import { Post, DownLoadReform } from './Request';
// 取消下载
export function CancelDownload(data) {
    return Post(`/verifyDownload/cancelExport?token=${data.token}`, data);
}
// 商品管理 - 下载校验
export function VerityGoodsProduct(data) {
    return Post(`/product/verifyProductExport`, data, true);
}
// 商品管理 - 下载
export function DownloadProductListList(data) {
    return DownLoadReform(`/product/export?token=${data.token}`, "商品列表.xlsx", data);
}
// 店铺订单 - 下载校验
export function VerityShopOrder(data) {
    return Post("/order/verifyOrderStoreExport", data, true);
}
// 店铺订单 - 下载
export function DownloadShopOrderList(data) {
    return DownLoadReform(`/order/orderExport?token=${data.token}`, "店铺订单列表.xlsx", data);
}
// 发货管理 - 下载
export function ShopDownloadDeliveryList(data, name) {
    return DownLoadReform('/backend/shop/deliver/export', name, data);
}
//商品管理 - 导入商品 - 下载
export function DownloadBatchImportList(data) {
    return DownLoadReform(`/productbatch/item/export?id=${data.id}`, "商品批量导入结果.xlsx", data);
}
