import { DownLoadReform, Get, Post } from './Request';
// 红包池列表参数
export class PoolListResponse {
}
// 红包池列表发放记录参数
export class GrantRecordResponse {
}
// 红包池列表 - 追加预算记录
export class AddRechargeResponse {
}
// 用户红包余额列表
export class UserEnvelopesResponse {
}
// 融合记录列表
export class UserFuseRecordResponse {
}
// 用户红包历史记录列表
export class UserHistoryResponse {
}
// 创建红包
export function CreateRedEnvelopes(data) {
    return Post("/redenvelopes/create", data);
}
// 红包池列表
export function RedEnvelopesList(data) {
    return Post("/redenvelopes/pool/list", data);
}
// 红包池延期
export function DelayRedEnvelopes(data) {
    return Post("/redenvelopes/postpone", data);
}
// 红包池充值
export function RechargeRedEnvelopes(data) {
    return Post("/redenvelopes/recharge", data);
}
// 获取随机码
export function RandomCode() {
    return Get(`/randomCode/generatedCode`);
}
// 活动方列表
export function ActivityList(data) {
    return Get(`/redenvelopes/activityList?merchantStoreId=${data.merchantStoreId}`);
}
// 停用红包
export function StopEnvelopes(data) {
    return Post("/redenvelopes/pool/disable", data);
}
// 红包池发放记录
export function ProvideRecord(data) {
    return Post("/redenvelopes/record/pool/list", data);
}
// 红包池列表发放金额，消费金额查询
export function MoneyData(data) {
    return Get(`/redenvelopes/pool/useDate?redEnvelopesPoolNo=${data.redEnvelopesPoolNo}`);
}
// 红包池 - 追加预算
export function AddRecharge(data) {
    return Post("/redenvelopes/recharge", data);
}
// 红包池列表 - 追加预算记录 
export function RechargeRecord(data) {
    return Get(`/redenvelopes/rechargeList?redEnvelopesPoolNo=${data.redEnvelopesPoolNo}&pageNum=${data.pageNum}&pageSize=${data.pageSize}`);
}
// 红包池 - 延期 
export function DelayEnvelopesTime(data) {
    return Post("/redenvelopes/postpone", data);
}
// 用户红包余额列表
export function UserEnvelopes(data) {
    return Post("/redenvelopes/account/list", data);
}
// 用户红包融合记录
export function UserFuseRecord(data) {
    return Get(`/redenvelopes/account/fusionList?accountId=${data.accountId}`);
}
// 用户红包历史记录
export function UserHistory(data) {
    return Post(`/redenvelopes/record/account/list`, data);
}
// 用户红包 - 消费记录
export function UserConsumeRecord(data) {
    return Get(`/redenvelopes/account/useDate?merchantStoreId=${data.merchantStoreId}&obtainUser=${data.obtainUser}`);
}
// 红包导出校验
export function VerityEnvelopesList(data) {
    return Post("/redenvelopes/verifyExport", data, true);
}
// 下载红包
export function DownloadEnvelopesList(data, name) {
    return DownLoadReform(`/redenvelopes/export?token=${data.token}`, name + '.xlsx');
}
// 红包池预警调整
export function WarningEdit(data) {
    return Post('/redenvelopes/pool/warnning/edit', data);
}
