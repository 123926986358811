import { defineComponent, onMounted, ref } from "vue";
import { CancelDownload } from "@/api/DownloadApi";
export default defineComponent({
    props: {
        name: {
            type: String,
            require: false,
        },
        verifyapi: {
            type: Function,
            default() {
                return "";
            },
        },
        downloadapi: {
            type: Function,
            default() {
                return "";
            },
        },
        params: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    emits: ["close"],
    setup(props, { emit }) {
        const btnDisabled = ref(true);
        const downloadVisible = ref(true);
        const downsucc = ref(false);
        const loading = ref(false);
        const tipText = ref("");
        const token = ref("");
        onMounted(() => {
            tipText.value = "正在生成文件.....";
            downsucc.value = false;
            props
                .verifyapi(props.params)
                .then((res) => {
                if (res.isSuccess) {
                    tipText.value = "文件生成成功~";
                    token.value = res.resultData;
                    btnDisabled.value = false;
                    return;
                }
                tipText.value = res.resultMsg;
            })
                .catch((err) => {
                tipText.value = err.resultMsg;
            });
        });
        const download = async () => {
            downsucc.value = true;
            loading.value = true;
            props
                .downloadapi({ token: token.value }, props.name || "")
                .then((res) => {
                loading.value = false;
                if (res.resultMsg) {
                    tipText.value = res.resultMsg;
                    downsucc.value = false;
                    btnDisabled.value = true;
                    return;
                }
                emit("close");
            });
        };
        const cancle = () => {
            loading.value = false;
            if (downsucc.value) {
                CancelDownload({
                    token: token.value,
                }).then(() => {
                    emit("close");
                    downloadVisible.value = false;
                });
                return;
            }
            downloadVisible.value = false;
            emit("close");
        };
        const close = () => {
            emit("close");
        };
        return {
            loading,
            btnDisabled,
            downloadVisible,
            tipText,
            download,
            cancle,
            close,
        };
    },
});
